<template>
    <st-page
        :title="$t('RESPONSE_TEMPLATES.LIST.TITLE')"
    >
        <template #toolbar>
            <response-templates-list-toolbar/>
        </template>
        <response-templates-list-filter>
            <response-templates-list-table/>
        </response-templates-list-filter>
    </st-page>
</template>

<script>
    import ResponseTemplatesListFilter from '../components/ResponseTemplatesListFilter';
    import ResponseTemplatesListTable from '../components/ResponseTemplatesListTable';
    import ResponseTemplatesListToolbar from '../components/ResponseTemplatesListToolbar';

    export default {
        name: 'ResponseTemplatesList',
        components: {
            ResponseTemplatesListFilter,
            ResponseTemplatesListTable,
            ResponseTemplatesListToolbar,
        },
    }
</script>
