<template>
    <div>
        <st-button
            variant="secondary"
            :callback="addResponseTemplate"
            v-can:create="'responseTemplates'"
        >
            <i class="fa fa-plus"></i>
            {{ $t('RESPONSE_TEMPLATES.ADD_BUTTON') }}
        </st-button>

        <response-templates-form-modal
            v-if="showModal"
            ref="responseTemplatesFormModal"
            @close="dismissModal"
            :edit-mode="editMode"
        ></response-templates-form-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ResponseTemplatesFormModal from '@/modules/response-templates/components/ResponseTemplatesFormModal.vue';

    export default {
        name: 'ResponseTemplatesListToolbar',
        components: { ResponseTemplatesFormModal },
        watch: {
            openEditing(newValue, oldValue) {
                if (newValue === oldValue || !newValue) return false;
                this.editMode = true;
                this.showModal = true;
          }
        },
        data() {
            return {
                showModal: false,
                editMode: false,
            };
        },
        computed: {
            ...mapGetters({
                record: 'responseTemplates/form/record',
                openEditing: 'responseTemplates/form/openEditing'
            }),
        },
        methods: {
            ...mapActions({
                unselectRecord: 'responseTemplates/form/unselect',
            }),
            addResponseTemplate() {
                this.showModal = true;
                this.editMode = false;
            },
            dismissModal() {
                this.showModal = false;
                if (this.editMode) {
                    this.unselectRecord();
                }
            }
        }
    };
</script>

