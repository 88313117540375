import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`RESPONSE_TEMPLATES.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`RESPONSE_TEMPLATES.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`RESPONSE_TEMPLATES.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    title: new StringField('title', label('title'), {
        required: true,
    }, { placeholder: placeholder('title') }),
    message: new StringField('message', label('message'), { required: true, }, { placeholder: placeholder('message') }),
    category: new EnumField('category', label('category'), [
        { value: 'complains', text: enumLabel('category', 'complains') },
        { value: 'applications', text: enumLabel('category', 'applications') },
        { value: 'notice', text: enumLabel('category', 'notice') }
    ], { required: true }, { placeholder: placeholder('category') }),
    categoryTranslated: new EnumField('categoryTranslated', label('category'), [
        { value: 'complains', text: enumLabel('category', 'complains') },
        { value: 'applications', text: enumLabel('category', 'applications') },
        { value: 'notice', text: enumLabel('category', 'notice') }
    ], { required: true }, { placeholder: placeholder('category') }),
};

export class ResponseTemplatesModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
