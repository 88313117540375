<template>
    <st-filters-pagination
        stateModule="responseTemplates/list"
        :filters="filters"
        :total="total"
        :moreFilters="false"
        @change="doFilter"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ResponseTemplatesModel } from '@/modules/response-templates/response-templates-model';

    const { fields } = ResponseTemplatesModel;

    const filterSchema = new FilterSchema([
        fields.title,
        fields.category,
    ]);

    export default {
        name: 'ResponseTemplatesListFilter',
        data() {
            return {
                rules: filterSchema.rules(),
            };
        },
        computed: {
            ...mapGetters({
               total: 'responseTemplates/list/total',
            }),
            fields() {
                return fields;
            },
            filters() {
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'responseTemplates/list/doFetch',
            }),
            doFilter(params) {
                this.doFetch(params);
            },
        },

    };
</script>