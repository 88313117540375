<template>
    <div>
        <st-modal
            id="st-modal-response-template"
            hide-header-delimiter
            hide-footer-delimiter
            no-close-on-backdrop
            no-close-on-esc
            :visible="visible"
            size="lg"
            @hide="hide"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <form class="form" novalidate="novalidate" id="st_modal_response_template_form">
                    <div class="form-group">
                        <label>
                            {{ fields.title.label }} *
                        </label>
                        <st-input-text
                            ref="title"
                            name="title"
                            v-model="model.title"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.category.label }} *
                        </label>
                        <b-form-select
                            v-model="model.category"
                            :options="fields.category.options"
                            ref="category"
                            name="category"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>{{ $t('GENERAL.SELECT_PLACEHOLDER') }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.message.label }} *
                        </label>
                    </div>
                    <div>
                        <st-richtext-editor formId="st_modal_response_template_form" v-model="model.message"/>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSubmit"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
    import KTUtil from '@/assets/js/components/util';
    // FormValidation plugins
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import StRichtextEditor from '@/shared/components/StRichtextEditor';
    import { FormValidator } from '@syncfusion/ej2-inputs';

    import { FormSchema } from '@/shared/form/form-schema';
    import { ResponseTemplatesModel } from '@/modules/response-templates/response-templates-model';

    const { fields } = ResponseTemplatesModel;

    const formSchema = new FormSchema([
        fields.id,
        fields.title,
        fields.category,
        fields.message,
    ]);

    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'ResponseTemplatesFormModal',
        components: { StRichtextEditor },
        props: {
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                visible: false,
                rules: formSchema.rules(),
                model: null
            };
        },
        computed: {
            ...mapGetters({
                record: 'responseTemplates/form/record',
                filters: 'responseTemplates/list/filters',
            }),
            headerText() {
                return this.editMode ?
                    this.$t('RESPONSE_TEMPLATES.FORM.EDIT_TITLE') :
                    this.$t('RESPONSE_TEMPLATES.FORM.ADD_TITLE');
            },
            fields() {
                return fields;
            }
        },
        methods: {
            ...mapActions({
                doCreate: 'responseTemplates/form/create',
                doUpdate: 'responseTemplates/form/update',
                doFetch: 'responseTemplates/list/doFetch',
            }),
            hide() {
                this.$emit('close');
                this.doReset();
            },
            doReset() {
                this.model = formSchema.initialValues(this.record);
            },
            open() {
                this.visible = true;
            },
            async doSubmit() {
                const validate = await this.fv.validate();
                if (validate === 'Valid' && this.model.message) {
                    const { id, ...values } = formSchema.cast(this.model);
                    if (this.editMode) {
                        this.doUpdate({ id, values }).then(() => this.hide());
                    } else {
                        this.doCreate(values).then(() => {
                            this.hide();
                            this.doFetch(this.filters);
                        });
                    }
                }
            }
        },
        async created() {
            this.model = formSchema.initialValues(this.record);
        },
        mounted() {
            // Initialize the form after the modal is shown
            this.$root.$on('bv::modal::shown', (modalObj) => {
                // this.$refs.rteInstance.refreshUI(); 
                if (modalObj.componentId === 'st-modal-response-template') {
                    const validationRules = this.rules;
                    const entity_form = KTUtil.getById(st_modal_response_template_form);
                    this.fv = formValidation(entity_form, {
                        fields: validationRules,
                        plugins: {
                            trigger: new Trigger(),
                            bootstrap: new Bootstrap()
                        }
                    });
                }
            });
        }
    };
</script>

